@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.navbarItem{
  @apply bg-green-100 px-4 py-2 mb-1 rounded-t-xl;
  cursor: pointer;
}
.selected{
  background-color: white;
  @apply mb-0;
}
.settingPanelWidget{
  @apply bg-gray-200 my-[20px] mx-[10px] p-10 rounded-3xl;
}
.settingLabel{
  @apply text-gray-500 text-left;
}
.slider{
  @apply w-full bg-white;
}
#settingPanel{
  overflow-y: auto;
}
.profileWidget{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profileGradientWidget{
  position: absolute;
  top:0;
  transform: translate(-50%, -50%);
}
#profileRoundText{
  position: absolute;
  top:0;
  transform: translate(-50%, -50%);
}
#tutorial p{
  @apply py-6;
}
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (min-width:768px) {
  #mobileMenu{
    display: block!important;
  }
}

